define([
        'modules/upx/models/model',
        './structs/ItemType'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'CalendarModule',

            object: 'ItemType'
        });
    }
);