define([
        'modules/upx/models/model',
        './structs/Invoice'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'BillingModule',

            object: 'Invoice',
            attachPaymentIdsTo: function (parameters, ajaxOptions) {
                return this.callObjectMethod('attachPaymentIdsToInvoice',parameters, ajaxOptions);
            },
            getPdfUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getInvoicePdfUrl',parameters, ajaxOptions);
            },
            markAsSend: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markInvoiceAsSend',parameters, ajaxOptions);
            },
            send: function (parameters, ajaxOptions) {
                return this.callObjectMethod('sendInvoice',parameters, ajaxOptions);
            },
            getEmail: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getInvoiceEmail',parameters, ajaxOptions);
            },
            markAsPaid: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markInvoiceAsPaid',parameters, ajaxOptions);
            },
            markMultipleAsPaid: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markMultipleInvoicesAsPaid',parameters, ajaxOptions);
            },
            getPdfHtml: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getInvoicePdfHtml',parameters, ajaxOptions);
            },
            setNotPaidComment: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setInvoiceNotPaidComment',parameters, ajaxOptions);
            }
        });
    }
);