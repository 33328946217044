define([
        'modules/upx/collections/collection',
        '../models/Feature',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ManagementModule',
            object: 'Feature',
            collection_method: 'listFeatures',
            model: Model
        });
    }
);