define([
        'modules/upx/models/model',
        './structs/Subscription'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ManagementModule',

            object: 'Subscription',
            getDetailsByAlias: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getSubscriptionDetailsByAlias',parameters, ajaxOptions);
            },
            setLevelByName: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setSubscriptionLevelByName',parameters, ajaxOptions);
            },
            getLevelDifferenceByName: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getSubscriptionLevelDifferenceByName',parameters, ajaxOptions);
            }
        });
    }
);