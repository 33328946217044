define([
        'modules/upx/collections/collection',
        '../models/AdminProfile',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'CommerceModule',
            object: 'AdminProfile',
            collection_method: 'listAdminProfiles',
            model: Model
        });
    }
);